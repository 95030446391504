import App from "next/app"
import React from "react"
import { FontStyles } from "../lib/fonts"
import { BaseCSS } from "styled-bootstrap-grid"
import "../public/css/app.css"
import { createMediaStyle } from "../Components/Responsive"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { fairyDustCursor } from "../helpers/sparkles"

require("intersection-observer")

export default class MyApp extends App {
  render() {
    const { Component, pageProps } = this.props

    if (typeof document !== "undefined") {
      fairyDustCursor({ colors: ["#b07ad4", "#51e5ff", "#f3ff51"] })
    }

    return (
      <>
        <FontStyles />
        <BaseCSS />
        <style type="text/css">{createMediaStyle()}</style>
        <link href="/css/normalize.css" rel="stylesheet" type="text/css" />
        <link href="/css/components.css" rel="stylesheet" type="text/css" />
        <link href="/css/fonts.css" rel="stylesheet" type="text/css" />

        <link href="/images/favicon.png" rel="shortcut icon" type="image/x-icon" />
        <link href="/images/webclip.png" rel="apple-touch-icon" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
        <Component {...pageProps} />
      </>
    )
  }
}
